<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Lista de Precios - Servicios</h4>
            <div class="small text-muted">Cargar de servicios a la lista de precios.</div>
          </b-col>           
        </b-row>
      </b-card>
      
      <b-row>
        <b-col>
          
          <b-card title="Servicios sin agregar" 
                  header-tag="header" 
                  footer-tag="footer">      
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <b-input-group>
                    <b-form-input type="text" size="sm" placeholder="Ingrese su busqueda para filtrar la grilla" v-model="tableSub.filter"></b-form-input>              
                  </b-input-group>
                </b-form-group>                
              </b-col>

              <b-col md="12">
                <b-table class="mb-0"
                        responsive="sm"
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="false"
                        :items="table.items"
                        :fields="table.fields"
                        :filter="table.filter"
                        :current-page="table.currentPage"
                        :per-page="table.perPage"                    
                        v-if="table.items.length">                       

                    <template v-slot:cell(id)="data">
                      <strong>{{data.item.id}}</strong>
                    </template>

                    <template v-slot:cell(name)="data">
                      <b>{{data.item.category.name}}</b>
                      <b-icon icon="circle-fill" v-if="!data.item.active" class="ml-2" variant="danger" v-b-tooltip.hover title="Servicio INACTIVO" />                                                 
                      <div>
                        <b-icon icon="chevron-double-right"></b-icon> {{data.item.name}}
                      </div>                       
                    </template>

                    <template v-slot:cell(f_action)="data">                      
                        <b-button size="sm" 
                                  @click="add(data.item)" 
                                  variant="outline-dark" 
                                  title="Agregar Servicio"
                                  class="pull-right">
                          <b-icon icon="arrow-right-short"></b-icon>
                        </b-button>                                                                      
                    </template>
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col>
                <nav>
                  <b-pagination class="pull-right mb-0"
                                size="sm"
                                pills=""
                                :total-rows="getRowCount(table.items)"
                                :per-page="table.perPage"
                                v-model="table.currentPage" />
                </nav>
              </b-col>                    
            </b-row>
          </b-card>
          
        </b-col>

        <b-col v-if="list">          
          <b-card :title="'Lista ID:' + this.listID + ' - ' + this.list.name + ' - ' + this.list.currency.code" 
                  header-tag="header" 
                  footer-tag="footer">      

            <b-row>
              <b-col md="12">
                <b-form-group>
                  <b-input-group>
                    <b-form-input type="text" size="sm" placeholder="Ingrese su busqueda para filtrar la grilla" v-model="tableSub.filter"></b-form-input>              
                  </b-input-group>
                </b-form-group>                
              </b-col>
              <b-col md="12">
                <b-table class="mb-0"
                        responsive="sm"
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="false"
                        :items="tableSub.items"
                        :fields="tableSub.fields"
                        :filter="tableSub.filter"
                        :current-page="tableSub.currentPage"
                        :per-page="tableSub.perPage"                    
                        v-if="tableSub.items.length">      

                    <template v-slot:cell(id)="data">
                      <strong>{{data.item.services.id}}</strong>
                    </template>

                    <template v-slot:cell(amount)="data">
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency: data.item.list.currency.code }).format(data.item.amount)}}                      
                    </template>
                    
                    <template v-slot:cell(name)="data">
                      <b>{{data.item.services.category.name}}</b>
                      <b-icon icon="circle-fill" v-if="!data.item.services.active" class="ml-2" variant="danger" v-b-tooltip.hover title="Servicio INACTIVO" />                                                 
                      <div>
                        <b-icon icon="chevron-double-right"></b-icon> {{data.item.services.name}}
                      </div>                        
                    </template>
                    
                    <template v-slot:cell(f_action)="data">
                      <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">                                                                         
                        <b-dropdown-item @click="edit(data.item)">
                          <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                        </b-dropdown-item>
                        <b-dropdown-item @click="remove(data.item)">
                          <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col>
                <nav>
                  <b-pagination class="pull-right mb-0"
                                size="sm"
                                pills=""
                                :total-rows="getRowCount(tableSub.items)"
                                :per-page="tableSub.perPage"
                                v-model="tableSub.currentPage" />
                </nav>
              </b-col>                    
            </b-row>
          </b-card>          
        </b-col>        
      </b-row>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>         
        </b-row>
      </b-card>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->      
      <b-modal v-model="modal.form.active"
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-row v-if="crud.form.services">
          <b-col md="12">
            <table class="table b-table table-sm table-hover b-table-caption-top">
              <thead class="thead-dark">       
                <tr>
                  <th colspan="4" class="text-center">SERVICIOS</th>
                </tr>
                <tr>
                  <th class="w-33">Código</th>
                  <th class="w-33">Nombre</th>                  
                  <th class="w-33">Precio</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{this.crud.form.services.code}}</td>
                  <td>{{this.crud.form.services.name}}</td>
                  <td>
                    <b-form-input type="number"                                    
                                  size="sm"    
                                  autofocus                              
                                  @focus="$event.target.select()"
                                  v-model="crud.form.amount">
                    </b-form-input>      
                  </td>
                </tr>
              </tbody>    
            </table>            
          </b-col>
                    
        </b-row>
        
        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal>
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'   
  import Param from '@/config/parameters'

  export default {
    data: () => {
      return {      
        access: {
          module_id: Modules.PRECIOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudPricesListsServices',
          elements: {}
        },        
        listID: 0,
        list: null,
        table : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true, class: 'align-middle'},
            {key: 'name', label: 'Nombre', class: 'align-middle'},                                            
            {key: 'f_action', label:'', class: 'align-middle'},
          ],
          filter: null,
          mostrarFiltros: false,
          currentPage: 1,
          perPage: 10,
        },
        tableSub : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true, class: 'align-middle'},
            {key: 'name', label: 'Nombre', class: 'align-middle'},            
            {key: 'amount', label: 'Precio', class: 'align-middle'},                              
            {key: 'f_action', label:'', class: 'align-middle'},
          ],
          filter: null,
          mostrarFiltros: false,
          currentPage: 1,
          perPage: 10,
        },                
        crud: {
          form: {
            id: 0,
            price_list_id: 0,
            services_id: 0,  
            services: null,             
            amount:0,                      
            amount_total:0,
          },           
        },
        modal: {
          form: {
            active: false,
            title: ''
          },          
        },                           
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {
      this.listID = this.$route.params.listID  
      this.loadList()

      this.load()
      this.loadSub()
    },  
    methods: {
      getRowCount (items) {
        return items.length
      },
      loadList() {        
        var result = serviceAPI.mostrarLista(this.listID)

        result.then((response) => {
          var data = response.data                         
          this.list = data
        })
      },
      load() {        
        var result = serviceAPI.obtenerServices({
          price_list_id: this.listID
        })

        result.then((response) => {
          var data = response.data
          this.table.items = data                  
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });    
      },
      loadSub() {        
        var result = serviceAPI.obtenerListaServicesByLista({
          price_list_id: this.listID
        })

        result.then((response) => {
          var data = response.data          
          this.tableSub.items = data    
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });    
      },

      add(item) {                
        this.crud.form.id = 0
        this.crud.form.price_list_id = this.listID
        this.crud.form.services_id = item.id
        this.crud.form.services = item
        this.crud.form.amount = 0
        this.crud.form.amount_total = 0                         
        
        this.modal.form.title = "Agregar Servicio"
        this.modal.form.active = true
      },
      edit(item) {
        var result = serviceAPI.mostrarServicesByLista({
          price_list_id: this.listID,
          services_id: item.services_id
        })

        result.then((response) => {
          var data = response.data
          
          this.crud.form.id = data.services.id
          this.crud.form.price_list_id = data.services.price_list_id
          this.crud.form.services_id = data.services.services_id
          this.crud.form.services = data.services.services         
          this.crud.form.amount = data.services.amount
          this.crud.form.amount_total = data.services.amount_total          

          this.modal.form.title = "Editar Servicio"
          this.modal.form.active = true

        })
        .catch(error => {          
          this.$awn.alert(Error.showError(error))
        });         
      },
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.services.name        

        this.$bvModal.msgBoxConfirm('¿Desea borrar el servicio '+ this.crud.form.name + ' de la lista ' + this.list.name + '?', {
          title: 'Quitar Servicio de la Lista',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarListaServices(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.load()
              this.loadSub()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {               
        let loader = this.$loading.show();      
      
        if (this.crud.form.id) {
          var result = serviceAPI.editarListaServices(this.crud.form);
        } else {
          var result = serviceAPI.agregarListaServices(this.crud.form);
        }

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.load()
          this.loadSub()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },            
    }    
  }
</script>
<style>
  
</style>